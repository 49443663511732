import sanityClient from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';

// export const client = sanityClient({
//   projectId: process.env.REACT_APP_SANITY_PROJECT_ID,
//   dataset: 'production',
//   apiVersion: '2022-02-01',
//   useCdn: true,
//   token: process.env.REACT_APP_SANITY_TOKEN,
// });

export const client = sanityClient({
  projectId: 'nxf4hpdr',
  dataset: 'production',
  apiVersion: '2022-02-01',
  useCdn: true,
  token: 'skxL2YR6QQ3opcQI1jkfLPkziTuinemiCAseJowdudoLyAHl52pkN0y21JFKC4E5QFJjIUekBfZwWlsoAGPV0Itk6TE8klqu7PFnPSJPOkmZsmstxPv792pKFNsalUwkR8GCPDEXrgDD6pvnx9Lh0hwYVaSM6FwLbOeTWiWuv72RQWnA2VAn',
});

const builder = imageUrlBuilder(client);

export const urlFor = (source) => builder.image(source);

