import React from 'react';

import {BsTwitter, BsInstagram, BsLinkedin, BsMedium, BsTelegram, BsSpotify} from 'react-icons/bs';
import {FaFacebookF} from 'react-icons/fa';

const SocialMedia = () => {
    return (
        <div className='app__social'>
            <div>
                <a href = "https://twitter.com/AdiChandrayan">
                    <BsTwitter/>
                </a>
            </div>
            <div>  
                <a href = "https://www.linkedin.com/in/adityachandrayan/">
                    <BsLinkedin />  
                </a>    
            </div>
        </div>
    )
}

export default SocialMedia
